import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
// import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { APIS, WHERE_TO_BUY, IMAGE_BASE_URL } from "./../../../constants";
import "./../styles/Home.scss";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airPurifierProductsLimit: 6,
      wallMountProductsLimit: 6,
      concealedCeilingProductsLimit: 6,
      ceilingSuspendedProductsLimit: 6,
      cassetteProductsLimit: 6,
      floorStandingProductsLimit: 6,
      ceilingMountedProductsLimit: 6,
      rooftopProductsLimit: 6,
      vrvIndoorProductsLimit: 6,
      vrvOutdoorProductsLimit: 6,
    };
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.getPdfUrl = this.getPdfUrl.bind(this);
  }

  getEncodedTitle = (seriesName) => {
    let encodedData = 'Find My Product | Daikin MEA';
    if (localStorage.getItem("selectedCountry") !== null && localStorage.getItem("selectedCountry") !== 'Select Country' && localStorage.getItem("selectedCountry") !== '') {
      encodedData = seriesName + ' | ' + localStorage.getItem("selectedCountry") + ' | ' + encodedData;
    } else {
      encodedData = seriesName + ' | ' + encodedData;
    }
    return btoa(encodedData);
  }

  getPdfUrl = (url) => {
    let path = '';
    path = url.substring(url.lastIndexOf('/') + 1);
    path = APIS.BASE_URL + '/storage/product-documents/ONE-PG-' + encodeURIComponent(path);
    return path;
  }

  // componentDidUpdate() {
  //   console.log('Hello');
  // }

  render() {
    console.log(this.props);
    return (
      <Fragment>
        <div style={{ minHeight: 500 }}>
          <div className="spacer"></div>
          {((this.props.airPurifierProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/air-purifier-products")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Air Purifier </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.airPurifierProducts
                          .slice(0, this.state.airPurifierProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) :
                                      (item.download_status === 0 ?
                                        (<img
                                          src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                          className="img-fluid img-fluid-height"
                                          alt={item.product_series_name}
                                        />) : (<iframe
                                          type="application/pdf"
                                          frameborder="0"
                                          width="100%"
                                          height="100%"
                                          seamless="seamless"
                                          class="responsive-iframe"
                                          src={this.getPdfUrl(item.document_url)}
                                          title="description"
                                          scrolling="no"
                                        ></iframe>))
                                    }
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="card-btn1 mr-2 mt-2">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/air-purifier-products/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="card-btn2 mt-2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.airPurifierProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.airPurifierProducts.length ===
                          this.state.airPurifierProductsLimit
                        ) {
                          this.setState({
                            airPurifierProductsLimit: 6,
                          });
                        } else if (
                          this.props.airPurifierProducts.length -
                          this.state.airPurifierProductsLimit <
                          6
                        ) {
                          this.setState({
                            airPurifierProductsLimit:
                              this.state.airPurifierProductsLimit +
                              this.props.airPurifierProducts.length -
                              this.state.airPurifierProductsLimit,
                          });
                        } else {
                          this.setState({
                            airPurifierProductsLimit:
                              this.state.airPurifierProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.airPurifierProducts.length >
                        this.state.airPurifierProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.airPurifierProducts.length ===
                        this.state.airPurifierProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}
          {((this.props.wallMountProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/wall-mount-products")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Wall Mounted </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.wallMountProducts
                          .slice(0, this.state.wallMountProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : item.document_key === 'data_book_document' ? (<img
                                      src={"./../img/databook_icon.png"}
                                      className="img-fluid img-fluid-height"
                                      alt={item.product_series_name}
                                    />) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="card-btn1 mt-2">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/wall-mount-products/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="card-btn2 mt-2 ml-2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.wallMountProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.wallMountProducts.length ===
                          this.state.wallMountProductsLimit
                        ) {
                          this.setState({
                            wallMountProductsLimit: 6,
                          });
                        } else if (
                          this.props.wallMountProducts.length -
                          this.state.wallMountProductsLimit <
                          6
                        ) {
                          this.setState({
                            wallMountProductsLimit:
                              this.state.wallMountProductsLimit +
                              this.props.wallMountProducts.length -
                              this.state.wallMountProductsLimit,
                          });
                        } else {
                          this.setState({
                            wallMountProductsLimit:
                              this.state.wallMountProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.wallMountProducts.length >
                        this.state.wallMountProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.wallMountProducts.length ===
                        this.state.wallMountProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}
          {((this.props.concealedCeilingProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/concealed-ceiling")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Concealed Ceiling </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.concealedCeilingProducts
                          .slice(0, this.state.concealedCeilingProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/concealed-ceiling/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.concealedCeilingProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.concealedCeilingProducts.length ===
                          this.state.concealedCeilingProductsLimit
                        ) {
                          this.setState({
                            concealedCeilingProductsLimit: 6,
                          });
                        } else if (
                          this.props.concealedCeilingProducts.length -
                          this.state.concealedCeilingProductsLimit <
                          6
                        ) {
                          this.setState({
                            concealedCeilingProductsLimit:
                              this.state.concealedCeilingProductsLimit +
                              this.props.concealedCeilingProducts.length -
                              this.state.concealedCeilingProductsLimit,
                          });
                        } else {
                          this.setState({
                            concealedCeilingProductsLimit:
                              this.state.concealedCeilingProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.concealedCeilingProducts.length >
                        this.state.concealedCeilingProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.concealedCeilingProducts.length ===
                        this.state.concealedCeilingProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          {((this.props.cassetteProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/cassette")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Cassette </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.cassetteProducts
                          .slice(0, this.state.cassetteProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/cassette/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.cassetteProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.cassetteProducts.length ===
                          this.state.cassetteProductsLimit
                        ) {
                          this.setState({
                            cassetteProductsLimit: 6,
                          });
                        } else if (
                          this.props.cassetteProducts.length -
                          this.state.cassetteProductsLimit <
                          6
                        ) {
                          this.setState({
                            cassetteProductsLimit:
                              this.state.cassetteProductsLimit +
                              this.props.cassetteProducts.length -
                              this.state.cassetteProductsLimit,
                          });
                        } else {
                          this.setState({
                            cassetteProductsLimit:
                              this.state.cassetteProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.cassetteProducts.length >
                        this.state.cassetteProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.cassetteProducts.length ===
                        this.state.cassetteProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}
          {((this.props.floorStandingProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/floor-standing")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Floor Standing </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.floorStandingProducts
                          .slice(0, this.state.floorStandingProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/floor-standing/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.floorStandingProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.floorStandingProducts.length ===
                          this.state.floorStandingProductsLimit
                        ) {
                          this.setState({
                            floorStandingProductsLimit: 6,
                          });
                        } else if (
                          this.props.floorStandingProducts.length -
                          this.state.floorStandingProductsLimit <
                          6
                        ) {
                          this.setState({
                            floorStandingProductsLimit:
                              this.state.floorStandingProductsLimit +
                              this.props.floorStandingProducts.length -
                              this.state.floorStandingProductsLimit,
                          });
                        } else {
                          this.setState({
                            floorStandingProductsLimit:
                              this.state.floorStandingProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.floorStandingProducts.length >
                        this.state.floorStandingProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.floorStandingProducts.length ===
                        this.state.floorStandingProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          {((this.props.ceilingSuspendedProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/ceiling-suspended")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Ceiling Suspended </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.ceilingSuspendedProducts
                          .slice(0, this.state.ceilingSuspendedProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/ceiling-suspended/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.ceilingSuspendedProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.ceilingSuspendedProducts.length ===
                          this.state.ceilingSuspendedProductsLimit
                        ) {
                          this.setState({
                            ceilingSuspendedProductsLimit: 6,
                          });
                        } else if (
                          this.props.ceilingSuspendedProducts.length -
                          this.state.ceilingSuspendedProductsLimit <
                          6
                        ) {
                          this.setState({
                            ceilingSuspendedProductsLimit:
                              this.state.ceilingSuspendedProductsLimit +
                              this.props.ceilingSuspendedProducts.length -
                              this.state.ceilingSuspendedProductsLimit,
                          });
                        } else {
                          this.setState({
                            ceilingSuspendedProductsLimit:
                              this.state.ceilingSuspendedProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.ceilingSuspendedProducts.length >
                        this.state.ceilingSuspendedProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.ceilingSuspendedProducts.length ===
                        this.state.ceilingSuspendedProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          {((this.props.ceilingMountedProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/ceiling-mounted")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Ceiling Mounted </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.ceilingMountedProducts
                          .slice(0, this.state.ceilingMountedProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/ceiling-mounted/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.ceilingMountedProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.ceilingMountedProducts.length ===
                          this.state.ceilingMountedProductsLimit
                        ) {
                          this.setState({
                            ceilingMountedProductsLimit: 6,
                          });
                        } else if (
                          this.props.ceilingMountedProducts.length -
                          this.state.ceilingMountedProductsLimit <
                          6
                        ) {
                          this.setState({
                            ceilingMountedProductsLimit:
                              this.state.ceilingMountedProductsLimit +
                              this.props.ceilingMountedProducts.length -
                              this.state.ceilingMountedProductsLimit,
                          });
                        } else {
                          this.setState({
                            ceilingMountedProductsLimit:
                              this.state.ceilingMountedProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.ceilingMountedProducts.length >
                        this.state.ceilingMountedProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.ceilingMountedProducts.length ===
                        this.state.ceilingMountedProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          {((this.props.rooftopProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/rooftops")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">Rooftops </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.rooftopProducts
                          .slice(0, this.state.rooftopProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/rooftops/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.rooftopProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.rooftopProducts.length ===
                          this.state.rooftopProductsLimit
                        ) {
                          this.setState({
                            rooftopProductsLimit: 6,
                          });
                        } else if (
                          this.props.rooftopProducts.length -
                          this.state.rooftopProductsLimit <
                          6
                        ) {
                          this.setState({
                            rooftopProductsLimit:
                              this.state.rooftopProductsLimit +
                              this.props.rooftopProducts.length -
                              this.state.rooftopProductsLimit,
                          });
                        } else {
                          this.setState({
                            rooftopProductsLimit:
                              this.state.rooftopProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.rooftopProducts.length >
                        this.state.rooftopProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.rooftopProducts.length ===
                        this.state.rooftopProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          {((this.props.vrvOutdoorProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/vrv-outdoors")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">VRV Outdoors </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.vrvOutdoorProducts
                          .slice(0, this.state.vrvOutdoorProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/vrv-outdoors/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 card-btn2 ml-2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.vrvOutdoorProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.vrvOutdoorProducts.length ===
                          this.state.vrvOutdoorProductsLimit
                        ) {
                          this.setState({
                            vrvOutdoorProductsLimit: 6,
                          });
                        } else if (
                          this.props.vrvOutdoorProducts.length -
                          this.state.vrvOutdoorProductsLimit <
                          6
                        ) {
                          this.setState({
                            vrvOutdoorProductsLimit:
                              this.state.vrvOutdoorProductsLimit +
                              this.props.vrvOutdoorProducts.length -
                              this.state.vrvOutdoorProductsLimit,
                          });
                        } else {
                          this.setState({
                            vrvOutdoorProductsLimit:
                              this.state.vrvOutdoorProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.vrvOutdoorProducts.length >
                        this.state.vrvOutdoorProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.vrvOutdoorProducts.length ===
                        this.state.vrvOutdoorProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          {((this.props.vrvIndoorProducts.length > 0 &&
            this.props.match.path === "/") ||
            this.props.match.url.match("/products/vrv-indoors")) && (
              <section>
                <div className="container-fluid">
                  <div>
                    <h3 className="text-center">VRV Indoors </h3>
                  </div>
                  <div className="list">
                    <div className="list-element">
                      <div className="row p-5">
                        {this.props.vrvIndoorProducts
                          .slice(0, this.state.vrvIndoorProductsLimit)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "30px" }}
                                className="col-md-2"
                              >
                                <div className="card">
                                  {/* {item.is_archived === 1 && (
                                    <div class="pro-tag-arc">
                                      <h5>ARCHIVED</h5>
                                    </div>
                                  )}
                                  {item.is_new === 1 && (
                                    <div class="pro-tag-nw">
                                      <h5>NEW</h5>
                                    </div>
                                  )} */}
                                  <div
                                    className="img-card"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {item.product_series_name !== undefined ? (
                                      <img
                                        src={
                                          item.featured_image != null
                                            ? IMAGE_BASE_URL +
                                            "/storage" +
                                            item.featured_image
                                            : "./../img/logo.svg"
                                        }
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />
                                    ) : (item.download_status === 0 ?
                                      (<img
                                        src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                        className="img-fluid img-fluid-height"
                                        alt={item.product_series_name}
                                      />) : (<iframe
                                        type="application/pdf"
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        seamless="seamless"
                                        class="responsive-iframe"
                                        src={this.getPdfUrl(item.document_url)}
                                        title="description"
                                        scrolling="no"
                                      ></iframe>)
                                    )}
                                  </div>
                                  <div className="card-head-text">
                                    {item.product_series_name !== undefined ? (
                                      <h1>
                                        {item.product_series_name + " Series"}
                                      </h1>
                                    ) : (
                                      <>
                                        <h1>{item.name}</h1>
                                        <h6>{item.document_name}</h6>
                                      </>
                                    )}
                                    <div className="row d-flex justify-content-center top-pad">
                                      <div className="mt-2 card-btn1">
                                        {item.product_series_name !==
                                          undefined ? (
                                          <Link
                                            to={`/products/vrv-indoors/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}`}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        ) : (
                                          <Link
                                            target="_blank"
                                            to={item.document_url.replace(
                                              "https://",
                                              "//"
                                            )}
                                          >
                                            <button>View Details</button>
                                          </Link>
                                        )}
                                      </div>
                                      {item.product_series_name !== undefined && (
                                        <div className="mt-2 ml-2 card-btn2">
                                          <Link
                                            target="_blank"
                                            to={`${WHERE_TO_BUY}`}
                                          >
                                            <button>Where to buy</button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {this.props.vrvIndoorProducts.length > 6 && (
                    <button
                      id="loadmore"
                      onClick={(e) => {
                        if (
                          this.props.vrvIndoorProducts.length ===
                          this.state.vrvIndoorProductsLimit
                        ) {
                          this.setState({
                            vrvIndoorProductsLimit: 6,
                          });
                        } else if (
                          this.props.vrvIndoorProducts.length -
                          this.state.vrvIndoorProductsLimit <
                          6
                        ) {
                          this.setState({
                            vrvIndoorProductsLimit:
                              this.state.vrvIndoorProductsLimit +
                              this.props.vrvIndoorProducts.length -
                              this.state.vrvIndoorProductsLimit,
                          });
                        } else {
                          this.setState({
                            vrvIndoorProductsLimit:
                              this.state.vrvIndoorProductsLimit + 6,
                          });
                        }
                      }}
                    >
                      {this.props.vrvIndoorProducts.length >
                        this.state.vrvIndoorProductsLimit && (
                          <span>Show More...</span>
                        )}
                      {this.props.vrvIndoorProducts.length ===
                        this.state.vrvIndoorProductsLimit && (
                          <span>Show less...</span>
                        )}
                    </button>
                  )}
                </div>
                <div className="spacer-1"></div>
              </section>
            )}

          <div className="spacer-1"></div>
        </div>
      </Fragment>
    );
  }
}

export default HomePage;

