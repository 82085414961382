export const APP = {
  REQUEST: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  CODE: {
    SUCCESS: 200,
  },
  DEVICES: {
    WINDOWS: "Win32",
    ANDROID: "Android",
    IOS: "iOS",
  },
};

export const WHERE_TO_BUY = '//www.daikinmea.com/en_us/dealer-locator.html#!?offset=0&language=en';
export const IMAGE_BASE_URL = 'https://admin.daikinmea.com';
// export const IMAGE_BASE_URL = "https://daikin.imcdevelopers.com"