import React from 'react';

const PdfViewer = () => {
    const myUrl2 = new URL(window.location.toLocaleString());
    // params_arr
    console.log(myUrl2['search'].substring(5));
    const url = decodeURI(myUrl2['search'].substring(5));

    return (
        <div style={{ height: '100vh' }}>
            <iframe
                type="application/pdf"
                width="100%"
                height="100%"
                class="responsive-iframe"
                src={'https://drive.google.com/viewerng/viewer?embedded=true&url=' + url}
                title="description"
            // scrolling="no"
            ></iframe>
        </div>
    );
}

export default PdfViewer;