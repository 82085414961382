import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "./../styles/Common.scss";
const $ = window.$;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId:
        localStorage.getItem("countryId") !== null
          ? localStorage.getItem("countryId")
          : "",
      selectedFlag:
        localStorage.getItem("selectedFlag") !== null
          ? localStorage.getItem("selectedFlag")
          : "",
      selectedCountry:
        localStorage.getItem("selectedCountry") !== null
          ? localStorage.getItem("selectedCountry")
          : "Select Country",
      productType: "",
      documentType: "",
      searchText: "",
      displayTopSearch: false,
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleDocumentChange = this.handleDocumentChange.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.resetDropdown = this.resetDropdown.bind(this);
  }

  componentDidMount = () => {
    // alert();
    if (document.documentElement.clientWidth > 991 && document.documentElement.clientWidth < 1451) {
      this.setState({
        displayTopSearch: true,
      })
    }
    localStorage.setItem("productType", "");
    localStorage.setItem("documentType", "");
    localStorage.setItem("searchText", "");
    document.getElementById("productType").value =
      localStorage.getItem("productType");
    document.getElementById("documentType").value =
      localStorage.getItem("documentType");
    document.getElementById("text-search").value =
      localStorage.getItem("searchText");
    setTimeout(() => {
      var clientHeight = document.getElementById('ci-1').clientHeight;
      document.getElementById('ci-2').height = clientHeight;

    }, 1000)

  };

  resetDropdown = (e) => {
    e.preventDefault();
    this.handleCountryChange("", "", "Select Country");
  };

  handleReset = (e) => {
    $('#close-img').click();
    $('#productType').val('');
    $('#documentType').val('');
    $('#text-search').val('');
    let data = {
      countryId: '',
      productType: '',
      documentType: '',
      searchText: '',
    };
    this.props.history.push("/");
    this.props.filterProducts(data);
  }

  handleCountryChange = (id, flag, name) => {
    localStorage.setItem("countryId", id);
    localStorage.setItem("selectedFlag", flag);
    localStorage.setItem("selectedCountry", name);
    localStorage.setItem("productType", "");
    localStorage.setItem("documentType", "");
    localStorage.setItem("searchText", "");
    this.setState({
      countryId: id,
      selectedFlag: flag,
      selectedCountry: name,
      productType: "",
      documentType: "",
      searchText: "",
    });
    setTimeout(() => {
      if (document.getElementById("productType"))
        document.getElementById("productType").value = "";
    }, 100);
    setTimeout(() => {
      if (document.getElementById("documentType"))
        document.getElementById("documentType").value = "";
    }, 100);
    setTimeout(() => {
      if (document.getElementById("text-search"))
        document.getElementById("text-search").value = "";
    }, 100);
    let data = {
      countryId: id,
      productType: "",
      documentType: "",
      searchText: "",
    };
    this.props.filterProducts(data);
  };

  handleProductChange = (e) => {
    localStorage.setItem("productType", e.currentTarget.value);
    this.setState({
      productType: e.currentTarget.value,
    });
  };

  handleDocumentChange = (e) => {
    localStorage.setItem("documentType", e.currentTarget.value);
    this.setState({
      documentType: e.currentTarget.value,
    });
  };

  handleSearchTextChange = (e) => {
    localStorage.setItem("searchText", e.currentTarget.value);
    this.setState({
      searchText: e.currentTarget.value,
    });
  };

  handleSubmit = (e) => {
    let data = {
      countryId: this.state.countryId,
      productType: this.state.productType,
      documentType: this.state.documentType,
      searchText: this.state.searchText,
    };
    this.props.history.push("/");
    this.props.filterProducts(data);
  };

  clearFilter = (e) => {
    // alert();
    this.setState({
      countryId: this.state.countryId,
      productType: "",
      documentType: "",
      searchText: "",
    });
    let data = {
      countryId: this.state.countryId,
      productType: "",
      documentType: "",
      searchText: "",
    };
    var element1 = document.getElementById("productType");
    var element2 = document.getElementById("documentType");
    if (element1) element1.value = "";
    if (element2) element2.value = "";
    localStorage.setItem("productType", "");
    localStorage.setItem("documentType", "");
    localStorage.setItem("searchText", "");
    this.props.filterProducts(data);
    setTimeout(() => (document.getElementById("text-search").value = ""), 100);
  };

  render() {
    console.log(this.props);
    return (
      <Fragment>
        <div id="loader"></div>
        <section>
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link
              to={"/"}
              onClick={(e) => this.clearFilter(e)}
              className="navbar-brand"
            >
              <img
                style={{ marginLeft: "1em" }}
                className="img-fluid"
                width="150"
                src="./../../../img/logo.svg"
                alt=""
              />
            </Link>
          </nav>
        </section>

        <section className="hero-banner">
          <div className="container-fluid">
            {/* {(this.props.match.path === "/" ||
              this.props.match.path === "/products/:productType") && ( */}
            <div className="row">

              <div className="col-md-12 middle-full-size">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    {/* <div className="carousel-item active">
                      <img
                        id="ci-1"
                        // style={{height: 420}}
                        onClick={(e) => {
                          this.props.history.push(
                            "/products/air-purifier-products/1/MC55"
                          );
                        }}
                        className="d-block w-100 img-fluid"
                        src="./../../../img/1.png"
                        alt="First slide"
                      />
                    </div> */}
                    <div className="carousel-item active">
                      <img
                        id="ci-1"
                        // style={{height: 420}}
                        onClick={(e) => {
                          this.props.history.push(
                            "/products/air-purifier-products/1/MC55"
                          );
                        }}
                        className="d-block w-100 img-fluid"
                        src="./../../../img/slider-1.jpg"
                        alt="First slide"
                      />
                    </div>
                    {/* <div className="carousel-item">
                      <iframe
                        title="caurosel"
                        onLoad="var clientHeight = document.getElementById('ci-1').clientHeight;
                      document.getElementById('ci-2').height = clientHeight"
                        id="ci-2"
                        className="d-block w-100 video"
                        src="https://www.youtube.com/embed/k09qimWGYAc"
                        alt="Second slide"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      >
                      </iframe>
                    </div> */}
                    {/* <div className="carousel-item">
                      <img
                        onClick={(e) => {
                          this.props.history.push(
                            "/products/air-purifier-products/3"
                          );
                        }}
                        className="d-block w-100"
                        src="./../../img/3.jpg"
                        alt="Third slide"
                      />
                    </div> */}
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
              </div>
              <div className="row justify-content-center">
              <nav className="navbar navbar-expand-lg navbar-light">
           
      
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">

                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match(
                      "/products/air-purifier-products"
                    ) && this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link
                    to={"/products/air-purifier-products"}
                    className="nav-link"
                  >
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/air-purifier.png"
                        alt=""
                      />
                      <p className="mpt">Air Purifier</p>
                    </center>
                  </Link>
                </li>

                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match(
                      "/products/wall-mount-products"
                    ) && this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link
                    to={"/products/wall-mount-products"}
                    className="nav-link"
                  >
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/wall-mounted.png"
                        alt=""
                      />
                      <p className="mpt">Wall Mounted</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match(
                      "/products/concealed-ceiling"
                    ) && this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link
                    to={"/products/concealed-ceiling"}
                    className="nav-link"
                  >
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/concealed-ceiling.png"
                        alt=""
                      />
                      <p className="mpt">Concealed Ceiling</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/cassette") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/cassette"} className="nav-link">
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/cassette.png"
                        alt=""
                      />
                      <p className="mpt">Cassette</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/floor-standing") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/floor-standing"} className="nav-link">
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/floor-stand.svg"
                        alt=""
                      />
                      <p className="mpt">Floor Standing</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/ceiling-suspended") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/ceiling-suspended"} className="nav-link">
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/ceiling-suspended.png"
                        alt=""
                      />
                      <p className="mpt">Ceiling Suspended</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/ceiling-mounted") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/ceiling-mounted"} className="nav-link">
                    <center>
                      <img
                        width="22"
                        height="22"
                        src="./../../../img/ceiling-mounted.png"
                        alt=""
                      />
                      <p className="mpt">Ceiling Mounted</p>
                    </center>
                  </Link>
                </li>

                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/rooftops") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/rooftops"} className="nav-link">
                    <center>
                      <img width="22" height="22" src="./../../../img/rooftop.png" alt="" />
                      <p className="mpt">Rooftops</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/vrv-outdoors") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/vrv-outdoors"} className="nav-link">
                    <center>
                      <img width="22" height="22" src="./../../../img/vrv-outdoor.png" alt="" />
                      <p className="mpt">VRV Outdoors</p>
                    </center>
                  </Link>
                </li>
                <li
                  onClick={(e) => this.clearFilter(e)}
                  className={
                    this.props.match.url.match("/products/vrv-indoors") &&
                      this.props.match.path === "/products/:productType"
                      ? `nav-item active`
                      : `nav-item`
                  }
                >
                  <Link to={"/products/vrv-indoors"} className="nav-link">
                    <center>
                      <img width="22" height="22" src="./../../../img/vrv-indoor.png" alt="" />
                      <p className="mpt">VRV Indoors</p>
                    </center>
                  </Link>
                </li>

              </ul>

              {/* <div className="lang-pick float-right">
                <select
                  // onChange={this.handleCountryChange}
                  className="lang-pick1"
                  name="countries"
                  id="countries"
                  is="ms-dropdown"
                  data-child-height="315"
                >
                  <option
                    value=""
                    // data-image-css={`flag ${country.abbreviation.toLowerCase()}`}
                    data-title="Select country"
                  >
                    Select country
                  </option>
                  {this.props.countries.map((country, i) => {
                    return (
                      <option
                        key={i}
                        onClick={(e) => alert()}
                        value={country.slug}
                        data-image-css={`flag ${country.abbreviation.toLowerCase()}`}
                        data-title={country.name}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;{country.name}
                      </option>
                    );
                  })}
                </select>
              </div> */}
            </div>
          </nav>
          </div>
          <div className="row">
              <div className="col-md-12 pt-4 pb-4 center-block align-self-center grey-bg" id="banner-left">
                <h3 className="search-text">Search</h3>
                <div className="head-line">

                  {/* {this.props.match.params.productId === undefined &&
                    this.props.match.path !== "/products/:productType" && (
                      <Fragment> */}
                  <div className="row justify-content-around mt-3">
                    <div className="grey-bg-1">
                      <div
                        className="btn btn-secondary country-dropdown dropdown-toggle"
                        // href="javascript:void(0)"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ height: '38px' }}
                      >
                        <span className="">
                          <span className="ms-dd-option-content">
                            <span className="ms-dd-label">
                              {/* <span
                        style={{marginRight: -10}}
                          className={`ms-dd-option-image flag ${this.state.selectedFlag.toLowerCase()}`}
                        >
                          &nbsp;
                        </span> */}
                              &nbsp;&nbsp;&nbsp;&nbsp;{this.state.selectedCountry}
                              <span className="ms-dd-arrow ms-dd-pointer-down"></span>
                            </span>
                          </span>
                        </span>
                      </div>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li
                          onClick={(e) =>
                            this.handleCountryChange("", "", "Select Country")
                          }
                          className="dropdown-item ms-list-option enabled"
                          title="Select Country"
                          data-ms-index=""
                        >
                          <span className="ms-middle">
                            <span className="ms-dd-option-image ">&nbsp;</span>
                            <span className="ms-dd-option-content">
                              <span className="ms-dd-label">
                                &nbsp;&nbsp;&nbsp;&nbsp;Select Country
                              </span>
                              <span className="ms-dd-desc"></span>
                            </span>
                          </span>
                        </li>
                        {this.props.countries.map((country, i) => {
                          if (country.abbreviation !== "NA")
                            return (
                              <li
                                key={i}
                                onClick={(e) =>
                                  this.handleCountryChange(
                                    country.id,
                                    country.flag,
                                    country.name
                                  )
                                }
                                className="dropdown-item ms-list-option enabled"
                                title={country.name}
                                data-ms-index={country.id}
                              >
                                <span className="ms-middle">
                                  {/* <span
                              className={`ms-dd-option-image flag ${country.abbreviation.toLowerCase()}`}
                            >
                              &nbsp;
                            </span> */}
                                  <img width={24} src={`https://daikin.imcdevelopers.com/storage/country_flags/${country.flag}`} alt="flag" />
                                  <span className="ms-dd-option-content">
                                    <span className="ms-dd-label">
                                      &nbsp;&nbsp;&nbsp;&nbsp;{country.name}
                                    </span>
                                    <span className="ms-dd-desc"></span>
                                  </span>
                                </span>
                              </li>
                            );
                          else return (<></>);
                        })}
                      </div>
                      <div
                        id="main-menu"
                        className="dropdown show"
                        style={{ marginRight: "50px", zIndex: 1 }}
                      >
                        {this.state.selectedFlag !== "" && (
                          <Fragment>
                            <img
                              style={{
                                bottom: 10,
                                right: 120,
                                // top: this.state.displayTopSearch == false ? 43: 30,
                                position: "absolute",
                                zIndex: 2,
                                cursor: "pointer",
                              }}
                              onClick={(e) => this.resetDropdown(e)}
                              src="../../../img/close.png"
                              className="pull-left"
                              id="close-img"
                              height={18}
                              alt=""
                            />{" "}
                          </Fragment>
                        )}

                      </div>
                    </div>

                    <div className="col-md-2 col-sm-6 col-6">

                      <div className="form-group">
                        <div className="grey-bg">
                          <select
                            onChange={(e) => this.handleProductChange(e)}
                            className="form-control1"
                            id="productType"
                            name="sellist1"
                          >
                            <option value="">Select Product Type</option>
                            <option value="air_purifier_products">
                              Air Purifier
                            </option>
                            <option value="wall_mount_products">
                              Wall Mounted
                            </option>
                            <option value="concealed_ceiling">
                              Concealed Ceiling
                            </option>
                            <option value="cassette">Cassette</option>
                            <option value="floor_standing">Floor Standing</option>
                            <option value="ceiling_suspended">Ceiling Suspended</option>
                            <option value="ceiling_mounted">Ceiling Mounted</option>
                            <option value="rooftops">Rooftops</option>
                            <option value="vrv_outdoors">VRV Outdoors</option>
                            <option value="vrv_indoors">VRV Indoors</option>
                            {/* <option value="">Multi Split</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-6">
                      <div className="form-group">
                        <div className="grey-bg">
                          <select
                            onChange={(e) => this.handleDocumentChange(e)}
                            className="form-control1"
                            id="documentType"
                            name="document-type"
                          >
                            <option value="">Select Document Type</option>
                            <option value="catalog_document">Catalogue</option>
                            <option value="certificate_document">
                              Certificates
                            </option>
                            <option value="iom_document">
                              Installation and Operation Manual
                            </option>
                            <option value="data_book_document">Data Book</option>
                            <option value="service_manual_document">
                              Service Manual
                            </option>
                            <option value="specification_guide">
                              Specification Guide
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-1 col-sm-1 col-1 align-right">
                      <button
                        onClick={(e) => this.handleSubmit(e)}
                        className="btn btn-search"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div> */}
                    {/* </div> */}
                    {/* <div className="row">
                    <div className="col-md-10">
                      <div className="or">OR</div>
                    </div>
                  </div> */}
                    {/* </Fragment>
                     )} */}
                    {/* <div className="row pt-2" align="center"> */}
                    <div className="col-md-2 col-sm-6 col-6">
                      <div className="form-group">
                        <div className="grey-bg">
                          <input
                            type="text"
                            id="text-search"
                            placeholder="Search By Keyword"
                            className="form-control1"
                            autoComplete="off"
                            onInput={(e) => this.handleSearchTextChange(e)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                this.handleSubmit(e);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="row justify-content-center mt-3">
                    {/* <div className="dotted-radius"> */}
                    <div onClick={(e) => this.handleReset(e)}>
                      <button className="btn btn-search grey br-4">
                        Reset
                      </button>
                    </div>
                    <div
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      <button className="btn btn-search blue br-4 ml-5">
                        Search
                      </button>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Header;

