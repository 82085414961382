import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import HomeContainer from "./components/home/containers/HomeContainer";
import ProductContainer from "./components/product/containers/ProductContainer";
import PdfViewer from "./components/home/views/PdfViewer";

/* Router function to enable routing between the various components
 * in the project with authentication as well as authorization
 */

const Router = (props) => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={HomeContainer} />
      <Route exact path="/products/:productType" component={HomeContainer} />
      <Route exact path="/products/:productType/:productId/:seriesId" component={ProductContainer} />
      <Route exact path="/pdf-viewer" component={PdfViewer} />
    </Switch>
  </BrowserRouter>
);

export default Router;
