import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "./../../../constants";
import "./../styles/Product.scss";

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productImage: "",
      viewLoaded: false,
    };
    this.openInNewTab = this.openInNewTab.bind(this);
    this.updateProductImage = this.updateProductImage.bind(this);
    this.initilizeProductImage = this.initilizeProductImage.bind(this);
    this.getBreadCrumb = this.getBreadCrumb.bind(this);
    this.manageNavigation = this.manageNavigation.bind(this);
  }

  componentDidMount = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.productDetails !== prevProps.productDetails) {
      if (this.props.productImages.length > 0) {
        this.setState({
          productImage: this.props.productImages[0].meta_value,
          viewLoaded: true,
        });
      }
      let title = '';
      if (localStorage.getItem("selectedCountry") !== null && localStorage.getItem("selectedCountry").toLowerCase() !== 'select country') {
        title += `${this.props.productDetails.length > 0 ? this.props.productDetails[0]["product_series_name"] : ''} | ${localStorage.getItem("selectedCountry")} | Find My Product | Daikin MEA`;
      } else {
        title += `${this.props.productDetails.length > 0 ? this.props.productDetails[0]["product_series_name"] : ''} | Find My Product | Daikin MEA`;
      }
      document.title = title;
    }
  };

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  initilizeProductImage = () => {
    if (this.props.productImages.length > 0) {
      this.setState({
        productImage: this.props.productImages[0].meta_value,
        viewLoaded: true,
      });
    }
  };

  updateProductImage = (image) => {
    this.setState({
      productImage: image,
    });
  };

  openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  getBreadCrumb = (name) => {
    name = name.replace("-products", "");
    name = name.replace("-", " ");
    // name = name.replace("mount", "mounted");
    name = name.replace("vrv", "VRV");
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  manageNavigation = (index) => {
    if (this.props.mediaImageLink[index] !== null) {
      if (this.props.mediaImageLink[index] !== '') {
        window.location = this.props.mediaImageLink[index].trim();
      }
    }
  }

  getEncodedTitle(seriesName) {
    let encodedData = 'Find My Product | Daikin MEA';
    if (localStorage.getItem("selectedCountry") !== null && localStorage.getItem("selectedCountry") !== 'Select Country' && localStorage.getItem("selectedCountry") !== '') {
      encodedData = seriesName + ' | ' + localStorage.getItem("selectedCountry") + ' | ' + encodedData;
    } else {
      encodedData = seriesName + ' | ' + encodedData;
    }
    return btoa(encodedData);
  }

  render() {
    // console.log(this.props.products);
    if (!this.state.viewLoaded) {
      setTimeout(() => this.initilizeProductImage(), 100);
    }
    return (
      <Fragment>
        <div className="spacer"></div>
        <section>
          <div className="container" style={{ minHeight: 340 }}>
            <div className="row">
              <div className="col-md-5 MT20">
                <div className="breadcrumbs">
                  <Link to="/">
                    <span className="fa fa-home"></span>{" "}
                  </Link>{" "}
                  /{" "}
                  <Link to={"/products/" + this.props.match.params.productType}>
                    <span>
                      {this.getBreadCrumb(this.props.match.params.productType)}
                    </span>{" "}
                  </Link>{" "}
                  /{" "}
                  <span>
                    {this.props.productDetails[0] != null
                      ? this.props.productDetails[0]["product_series_name"]
                      : ""}
                  </span>
                </div>
                {this.props.products.length > 0 && (
                  <Fragment>
                    <div className="row">
                      <div className="col-md-2 mg-pro-20">
                        {this.props.productImages.map((image, i) => {
                          return (
                            <div
                              key={i}
                              to="#"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.updateProductImage(image.meta_value)
                              }
                            >
                              {" "}
                              <img
                                className={
                                  this.state.productImage === image.meta_value
                                    ? "prod-lst-img  pro-back bordered-img"
                                    : "prod-lst-img  pro-back"
                                }
                                width="75"
                                height="50"
                                src={`${IMAGE_BASE_URL}/storage${image.meta_value}`}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-10">
                        <div className="showing pro-back">
                          {this.state.productImage !== "" && (
                            <Link to="#">
                              <img
                                style={{ marginTop: "0px" }}
                                className="prod-lst-img"
                                width="75"
                                height="50"
                                src={`${IMAGE_BASE_URL}/storage${this.state.productImage}`}
                                alt=""
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="spacer"></div>

                    <div className="row">
                      <div className="col-md-12">
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                          <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                          </ol>
                          <div className="carousel-inner">
                            {this.props.mediaGallery.map((image, i) => {
                              return (
                                <div
                                  key={'image-' + i}
                                  onClick={e => this.manageNavigation(i)}
                                  className={
                                    i === 0
                                      ? "carousel-item active"
                                      : "carousel-item"
                                  }
                                >
                                  <img
                                    height="200px"
                                    className="d-block w-100"
                                    src={`${IMAGE_BASE_URL}/storage${image.meta_value}`}
                                    alt={image.meta_key}
                                  />
                                </div>
                              );
                            })}
                            {this.props.mediaVideoLink.map((video, j) => {
                              return (
                                <div
                                  key={'video-' + j}

                                  className={
                                    "active carousel-item "
                                  }
                                >
                                  <iframe
                                    height="200px"
                                    title={"caurosel " + j}
                                    className="d-block w-100 video"
                                    src={video.trim() + '?controls=1'}
                                    alt={"slide" + j}
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                  >
                                  </iframe>
                                </div>
                              );
                            })}
                          </div>
                          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
                {/* {this.props.history.location.pathname === '/products/air-purifier-products/1/MC55' && (
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <iframe
                        title="caurosel 2"
                        height={250}
                        className="d-block w-100 video"
                        src="https://www.youtube.com/embed/k09qimWGYAc"
                        alt="Second slide"
                      >
                      </iframe>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="col-md-7 MT20">
                <div className="row">
                  <div className="col-md-6">
                    <div className="product-title">
                      {this.props.productDetails[0] != null
                        ? this.props.productDetails[0]["product_series_name"] +
                        " Series"
                        : ""}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-right">
                      <button
                        onClick={(e) =>
                          this.openInNewTab(
                            this.props.productDetails[0]["website_page_url"]
                          )
                        }
                        type="button"
                        className="btn btn-1"
                      >
                        VISIT WEBSITE PAGE
                      </button>
                    </div>
                  </div>
                  {this.props.products.length > 0 && (
                    <div className="col-md-12">
                      <p>
                        {this.props.productDetails[0] != null
                          ? this.props.productDetails[0]["description"]
                          : ""}
                      </p>
                      <div className="row">
                        {this.props.match.params.productType !==
                          "air-purifier-products" && (
                            <Fragment>
                              {this.props.productDetails[0]["refrigerant"] !==
                                null && (
                                  <div className="col-md-4">
                                    <div className="product-fea ">
                                      <img
                                        height={24}
                                        src="./../../../img/Refrigerant.svg"
                                        alt="Refrigerant"
                                      />{" "}
                                      Refrigerant
                                    </div>
                                    <p className="PL-24">
                                      {this.props.productDetails[0] != null
                                        ? this.props.productDetails[0][
                                        "refrigerant"
                                        ]
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              {this.props.match.params.productType !==
                                "vrv-indoors" && (
                                  <div className="col-md-4">
                                    <div className="product-fea">
                                      <img
                                        height={24}
                                        src="./../../../img/Mode.svg"
                                        alt="Mode"
                                      />{" "}
                                      Mode
                                    </div>
                                    <p
                                      className="PL-24"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {this.props.productDetails[0]['mode'] != null
                                        ? this.capitalize(
                                          this.props.productDetails[0][
                                            "mode"
                                          ].replace("-", " ")
                                        )
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              <div className="col-md-4">
                                <div className="product-fea">
                                  <img
                                    height={24}
                                    src="./../../../img/Frequency.svg"
                                    alt="Frequency"
                                  />{" "}
                                  Frequency
                                </div>
                                <p className="PL-24">
                                  {this.props.productDetails[0] != null
                                    ? this.props.productDetails[0][
                                      "frequency"
                                    ] === "50-60-hz"
                                      ? "50/60 Hz"
                                      : this.props.productDetails[0][
                                        "frequency"
                                      ] === "50-hz"
                                        ? "50Hz"
                                        : this.props.productDetails[0][
                                          "frequency"
                                        ] === "60-hz"
                                          ? "60Hz"
                                          : ""
                                    : ""}
                                </p>
                              </div>
                            </Fragment>
                          )}
                        {this.props.productDetails[0]["technology"] !==
                          null && (
                            <div className="col-md-4">
                              <div className="product-fea">
                                <img
                                  height={24}
                                  src="./../../../img/Technology.svg"
                                  alt="Technology"
                                />{" "}
                                Technology
                              </div>
                              <p className="PL-24">
                                {this.props.productDetails[0] != null
                                  ? this.props.productDetails[0]["technology"]
                                  : ""}
                              </p>
                            </div>
                          )}
                        {(this.props.match.params.productType ===
                          "air-purifier-products" || this.props.match.params.productType ===
                          "wall-mount-products" || this.props.match.params.productType ===
                          "cassette" || this.props.match.params.productType ===
                          "floor-standing" || this.props.match.params.productType ===
                          "ceiling-mounted" || this.props.match.params.productType ===
                          "rooftop") && this.props.productDetails[0]["ambient"] != null && (
                            <div className="col-md-4">
                              <div className="product-fea">
                                <img
                                  height={24}
                                  src="./../../../img/Climate.svg"
                                  alt="Climate"
                                />{" "}
                                Climate
                              </div>
                              <p
                                className="PL-24"
                                style={{ textTransform: "capitalize" }}
                              >
                                {this.props.productDetails[0]["ambient"] != null
                                  ? this.props.productDetails[0]["ambient"] ===
                                    "high-amb-stand-amb"
                                    ? "High Ambient/Standard Ambient"
                                    : this.capitalize(
                                      this.props.productDetails[0][
                                        "ambient"
                                      ].replace("-", " ")
                                    )
                                  : ""}
                              </p>
                            </div>
                          )}
                        {this.props.match.params.productType ===
                          "vrv-outdoors" && (
                            <Fragment>
                              <div className="col-md-4">
                                <div className="product-fea">
                                  <img
                                    height={24}
                                    src="./../../../img/Technology.svg"
                                    alt="Commercial Name"
                                  />{" "}
                                  Commercial Name
                                </div>
                                <p className="PL-24">
                                  {this.props.productDetails[0]["commercial_name"] != null
                                    ? this.capitalize(
                                      this.props.productDetails[0][
                                      "commercial_name"
                                      ]
                                    )
                                    : ""}
                                </p>
                              </div>
                            </Fragment>
                          )}
                        {(this.props.match.params.productType ===
                          "vrv-indoors" ||
                          this.props.match.params.productType ===
                          "vrv-outdoors") && (
                            <Fragment>
                              <div className="col-md-4">
                                <div className="product-fea">
                                  <img
                                    height={24}
                                    src="./../../../img/Technology.svg"
                                    alt="Catagory"
                                  />{" "}
                                  Category
                                </div>
                                <p className="PL-24">
                                  {this.props.productDetails[0] != null
                                    ? this.capitalize(this.props.productCatagory)
                                    : ""}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <div className="product-fea">
                                  <img
                                    height={24}
                                    src="./../../../img/Technology.svg"
                                    alt="Sub Category"
                                  />{" "}
                                  Sub Category
                                </div>
                                <p className="PL-24">
                                  {this.props.productDetails[0] != null
                                    ? this.capitalize(
                                      this.props.productSubCatagory
                                    )
                                    : ""}
                                </p>
                              </div>
                            </Fragment>
                          )}
                      </div>

                    </div>
                  )}
                  {!this.props.loading && this.props.products.length === 0 && (
                    <div className="col-12 mt-5">
                      <h5>Not applicable in selected country</h5>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="product-fea">
                  <i className="fa fa-asterisk"></i> Product
                  Specifications
                </div>
              </div>
              {this.props.match.params.productType ===
                "air-purifier-products" && (
                  <table className="table tbl-brd table-striped">
                    <thead style={{ textAlign: "center" }}>
                      <tr>
                        <th>Product Name</th>
                        <th>Coverage Area (Sqm)</th>
                        {/* <th>Type</th> */}
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {this.props.products.map((product, i) => {
                        return (
                          <tr key={i}>
                            <td className="tbl-brd1">
                              {product.product_name !== null &&
                                product.product_name !== ""
                                ? product.product_name
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.coverage_area !== null &&
                                product.coverage_area !== ""
                                ? product.coverage_area
                                : "N/A"}
                            </td>
                            {/* <td className="tbl-brd1">{product.type}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}

              {(this.props.match.params.productType ===
                "wall-mount-products" ||
                this.props.match.params.productType ===
                "concealed-ceiling" ||
                this.props.match.params.productType ===
                "ceiling-mounted" ||
                this.props.match.params.productType === "cassette" ||
                this.props.match.params.productType ===
                "floor-standing") && (
                  <table className="table tbl-brd table-striped">
                    <thead className="th-fs" style={{ textAlign: "center" }}>
                      <tr className="tbl-brd">
                        <th colSpan="2">Product Name</th>
                        <th className="tbl-brd" colSpan="2">
                          Nom Cooling Capacity&nbsp;
                          {
                            this.props.products.length ? this.props.products[0].unit === 'KW' || this.props.products[0].unit === 'BTU' ? '(' + this.props.products[0].unit + ')' : '' : ''
                          }
                        </th>
                        <th className="tbl-brd" colSpan="2">
                          Nom Heating Capacity&nbsp;
                          {
                            this.props.products.length ? this.props.products[0].unit === 'KW' || this.props.products[0].unit === 'BTU' ? '(' + this.props.products[0].unit + ')' : '' : ''
                          }
                        </th>
                        <th className="tbl-brd" rowSpan="1">

                        </th>
                        <th className="tbl-brd" rowSpan="1">

                        </th>
                      </tr>
                      <tr className="tbl-brd">
                        <th className="tbl-brd">Indoor</th>
                        <th className="tbl-brd">Outdoor</th>
                        <th className="tbl-brd">Nom</th>
                        <th className="tbl-brd">Max</th>
                        <th className="tbl-brd">Nom</th>
                        <th className="tbl-brd">Max</th>
                        <th className="tbl-brd">EER Nominal</th>
                        <th className="tbl-brd">COP Nominal</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {this.props.products.map((product, i) => {
                        return (
                          <tr key={i}>
                            <td className="tbl-brd1">
                              {product.indoor_product_name !== null &&
                                product.indoor_product_name !== ""
                                ? product.indoor_product_name
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.outdoor_product_name !== null &&
                                product.outdoor_product_name !== ""
                                ? product.outdoor_product_name
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.cooling_cap_nom !== null &&
                                product.cooling_cap_nom !== ""
                                ? product.cooling_cap_nom
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.cooling_cap_max !== null &&
                                product.cooling_cap_max !== ""
                                ? product.cooling_cap_max
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.heating_cap_nom !== null &&
                                product.heating_cap_nom !== ""
                                ? product.heating_cap_nom
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.heating_cap_max !== null &&
                                product.heating_cap_max !== ""
                                ? product.heating_cap_max
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.eer_nominal !== null &&
                                product.eer_nominal !== ""
                                ? product.eer_nominal
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.cop_nominal !== null &&
                                product.cop_nominal !== ""
                                ? product.cop_nominal
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}

              {this.props.match.params.productType === "rooftops" && (
                <table className="table tbl-brd table-striped">
                  <thead className="th-fs" style={{ textAlign: "center" }}>
                    <tr className="tbl-brd">
                      <th>Product Name</th>
                      <th className="tbl-brd">
                        Nominal Cooling Capacity&nbsp;
                        {
                          this.props.products.length ? this.props.products[0].unit === 'KW' || this.props.products[0].unit === 'BTU' ? '(' + this.props.products[0].unit + ')' : '' : ''
                        }
                      </th>
                      <th className="tbl-brd">
                        Nominal Heating Capacity&nbsp;
                        {
                          this.props.products.length ? this.props.products[0].unit === 'KW' || this.props.products[0].unit === 'BTU' ? '(' + this.props.products[0].unit + ')' : '' : ''
                        }
                      </th>
                      <th className="tbl-brd">EER Nominal</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {this.props.products.map((product, i) => {
                      return (
                        <tr key={i}>
                          <td className="tbl-brd1">
                            {product.product_name !== null &&
                              product.product_name !== ""
                              ? product.product_name
                              : "N/A"}
                          </td>
                          <td className="tbl-brd1">
                            {product.cooling_cap_nom !== null &&
                              product.cooling_cap_nom !== ""
                              ? product.cooling_cap_nom
                              : "N/A"}
                          </td>

                          <td className="tbl-brd1">
                            {product.heating_cap_nom !== null &&
                              product.heating_cap_nom !== ""
                              ? product.heating_cap_nom
                              : "N/A"}
                          </td>
                          <td className="tbl-brd1">
                            {product.eer_nominal !== null &&
                              product.eer_nominal !== ""
                              ? product.eer_nominal
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {(this.props.match.params.productType === "vrv-indoors" ||
                this.props.match.params.productType ===
                "vrv-outdoors") && (
                  <table className="table tbl-brd table-striped">
                    <thead className="th-fs" style={{ textAlign: "center" }}>
                      <tr className="tbl-brd">
                        <th>Product Name</th>
                        <th className="tbl-brd">
                          Nominal Cooling Capacity&nbsp;
                          {
                            this.props.products.length ? this.props.products[0].unit === 'KW' || this.props.products[0].unit === 'BTU' ? '(' + this.props.products[0].unit + ')' : '' : ''
                          }
                        </th>
                        <th className="tbl-brd">
                          Nominal Heating Capacity&nbsp;
                          {
                            this.props.products.length ? this.props.products[0].unit === 'KW' || this.props.products[0].unit === 'BTU' ? '(' + this.props.products[0].unit + ')' : '' : ''
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {this.props.products.map((product, i) => {
                        return (
                          <tr key={i}>
                            <td className="tbl-brd1">
                              {product.product_name !== null &&
                                product.product_name !== ""
                                ? product.product_name
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.cooling_cap_nom !== null &&
                                product.cooling_cap_nom !== ""
                                ? product.cooling_cap_nom
                                : "N/A"}
                            </td>
                            <td className="tbl-brd1">
                              {product.heating_cap_nom !== null &&
                                product.heating_cap_nom !== ""
                                ? product.heating_cap_nom
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              {this.props.match.params.productType === 'vrv-outdoors' && (
                <Fragment>
                  <div className="product-fea">Applicable Indoors</div>
                  <div className="doc">
                    {this.props.vrvApplicableIndoors && Object.keys(this.props.vrvApplicableIndoors).map((key) => {
                      return (
                        <button
                          onClick={e => {
                            this.props.history.push(`/products/vrv-indoors/${this.props.vrvApplicableIndoors[`${key}`][0].id}/${this.props.vrvApplicableIndoors[`${key}`][0].product_series_name}?encoded_title=${this.getEncodedTitle(this.props.vrvApplicableIndoors[`${key}`][0].product_series_name)}`
                            );
                            this.setState({});
                            document.body.scrollTop = 0; // For Safari
                            document.documentElement.scrollTop = 0;
                            this.props.updateParent('vrv-indoors', this.props.vrvApplicableIndoors[`${key}`][0].id);
                          }}
                          key={this.props.vrvApplicableIndoors[`${key}`][0].id}
                          style={{ margin: "5px" }}
                          type="button"
                          className="btn btn-1"
                        >
                          {this.props.vrvApplicableIndoors[`${key}`][0].product_series_name}
                        </button>
                      );
                    })}
                  </div>
                </Fragment>
              )}
              {this.props.match.params.productType === 'vrv-indoors' && (
                <Fragment>
                  <div className="product-fea">Applicable Outdoors</div>
                  <div className="doc">
                    {this.props.vrvApplicableOutdoors && Object.keys(this.props.vrvApplicableOutdoors).map((key) => {
                      return (
                        <button
                          onClick={e => {
                            this.props.history.push(`/products/vrv-outdoors/${this.props.vrvApplicableOutdoors[`${key}`][0].id}/${this.props.vrvApplicableOutdoors[`${key}`][0].product_series_name}?encoded_title=${this.getEncodedTitle(this.props.vrvApplicableOutdoors[`${key}`][0].product_series_name)}`
                            );
                            this.setState({});
                            document.body.scrollTop = 0; // For Safari
                            document.documentElement.scrollTop = 0;
                            this.props.updateParent('vrv-outdoors', this.props.vrvApplicableOutdoors[`${key}`][0].id);
                          }}
                          key={this.props.vrvApplicableOutdoors[`${key}`][0].id}
                          style={{ margin: "5px" }}
                          type="button"
                          className="btn btn-1"
                        >
                          {this.props.vrvApplicableOutdoors[`${key}`][0].product_series_name}
                        </button>
                      );
                    })}
                  </div>
                </Fragment>
              )}
              <div className="col-md-12">

                <div className="product-fea">
                  <i className="fa fa-file"></i> Documentation
                </div>
                <div className="doc">
                  {this.props.catalogDocument.length > 0 && (
                    <Fragment>
                      <div className="title-doc">Catalogue</div>
                      {this.props.catalogDocument.map((item, i) => {
                        return (
                          <button
                            onClick={(e) =>
                              this.openInNewTab(item.document_url)
                            }
                            key={i}
                            style={{ margin: "5px" }}
                            type="button"
                            className="btn btn-1"
                          >
                            {item.document_name}
                          </button>
                        );
                      })}
                    </Fragment>
                  )}
                  {this.props.iomDocument.length > 0 && (
                    <Fragment>
                      <div className="spacer20"></div>
                      <div className="title-doc">
                        Installation and Operation Manual
                      </div>
                      {this.props.iomDocument.map((item, i) => {
                        return (
                          <button
                            onClick={(e) =>
                              this.openInNewTab(item.document_url)
                            }
                            key={i}
                            style={{ margin: "5px" }}
                            type="button"
                            className="btn btn-1"
                          >
                            {item.document_name}
                          </button>
                        );
                      })}
                    </Fragment>
                  )}
                  {this.props.dataBookDocument.length > 0 && (
                    <Fragment>
                      <div className="spacer20"></div>
                      <div className="title-doc">
                        Data Book <br />
                        {" "}<i className="fa fa-info-circle"></i>{" "}
                        <small>
                          Available only for Daikin ID users, if you don't have an account, please contact our sales team
                        </small>
                      </div>
                      {this.props.dataBookDocument.map((item, i) => {
                        return (
                          <button
                            onClick={(e) =>
                              this.openInNewTab(item.document_url)
                            }
                            key={i}
                            style={{ margin: "5px" }}
                            type="button"
                            className="btn btn-1"
                          >
                            {item.document_name}
                          </button>
                        );
                      })}
                    </Fragment>
                  )}
                  {this.props.serviceManualDocument.length > 0 && (
                    <Fragment>
                      <div className="spacer20"></div>
                      <div className="title-doc">Service Manual</div>
                      {this.props.serviceManualDocument.map((item, i) => {
                        return (
                          <button
                            onClick={(e) =>
                              this.openInNewTab(item.document_url)
                            }
                            key={i}
                            style={{ margin: "5px" }}
                            type="button"
                            className="btn btn-1"
                          >
                            {item.document_name}
                          </button>
                        );
                      })}
                    </Fragment>
                  )}
                  {/*{this.props.certificateDocument.length > 0 && (
                    <Fragment>
                      <div className="spacer20"></div>
                      <div className="title-doc">Certificates</div>
                      {this.props.certificateDocument.map((item, i) => {
                        return (
                          <button
                            onClick={(e) =>
                              this.openInNewTab(item.document_url)
                            }
                            key={i}
                            style={{ margin: "5px" }}
                            type="button"
                            className="btn btn-1"
                          >
                            {item.document_name}
                          </button>
                        );
                      })}
                    </Fragment>
                  )}*/}
                  {this.props.specificationGuide.length > 0 && (
                    <Fragment>
                      <div className="spacer20"></div>
                      <div className="title-doc">Specification Guide</div>
                      {this.props.specificationGuide.map((item, i) => {
                        return (
                          <button
                            onClick={(e) =>
                              this.openInNewTab(item.document_url)
                            }
                            key={i}
                            style={{ margin: "5px" }}
                            type="button"
                            className="btn btn-1"
                          >
                            {item.document_name}
                          </button>
                        );
                      })}
                    </Fragment>
                  )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default ProductView;
