import React, { Component, Fragment } from "react";
import "./../styles/Common.scss";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <section>
          <footer>
            <div className="footer-tb">
              <hr />
              <p>&copy; DAIKIN {new Date().getFullYear()}. ALL RIGHTS RESERVED</p>
            </div>
          </footer>
        </section>
      </Fragment>
    );
  }
}

export default Footer;
